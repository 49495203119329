
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        







































































































































































.dropdown-filter {
  .el-input__inner {
    min-height: 40px !important;

    .el-range-separator {
      height: 25px;
    }
  }

  .inner-checkbox {
    @apply tw-w-full tw-flex tw-justify-between;

    .el-checkbox__input.is-checked + .el-checkbox__label {
      @apply tw-font-semibold;
    }
  }

  &.is-multiple {
    .el-select-dropdown__item.selected::after {
      @apply tw-hidden;
    }
  }

  &.el-select-dropdown.is-multiple .el-select-dropdown__item.selected {
    @apply tw-text-jb-ink;

    background-color: '#F9F9FC' !important;
  }

  &.el-select-dropdown.is-multiple .el-select-dropdown__item.selected.hover {
    background: '#F9F9FC' !important;
  }

  &-option {
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
    align-items: center;

    &-default {
      display: flex;

      &__id {
        min-width: $--clb-layout-4;
      }

      &__name {
        color: $--clb-color-grey__grey-darker;
      }
    }

    &__notification {
      margin-left: $--clb-layout-1;
      font-weight: $--clb-font-weight__bold;
      font-size: $--clb-font-size-xs;

      &-notread {
        padding-right: $--clb-space-2;
      }
    }
  }

  &__info {
    float: right;
    color: $--clb-color-info;
    font-size: 13px;
    white-space: nowrap;
    overflow: hidden;
    position: absolute;
    text-overflow: ellipsis;
    padding: 0 $--clb-space-2;
  }
}
