
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        














































































































































































































































































































































.campaign-list-filters {
  .filter-group {
    @media (min-width: 1300px) {
      @apply tw-flex tw-justify-between;
    }

    input {
      &::placeholder {
        @apply tw-text-jb-ink;
      }
    }

    .el-input__inner {
      min-height: 36px !important;
      height: 36px !important;
    }

    .vue-slider-dot-tooltip-inner {
      @apply tw-leading-5 tw-p-space-2 tw-text-sm tw-bg-jb-indigo;
    }

    .vue-slider-ltr .vue-slider-process {
      @apply tw-bg-jb-indigo;
    }

    @media (min-width: 600px) {
      .campaign-list-filters-vertical,
      .campaign-list-filters-countries,
      .campaign-list-filters-refine,
      .campaign-list-filters-features, {
        width: 48%;
      }
    }

    @media (min-width: 1300px) {
      .campaign-list-filters-vertical,
      .campaign-list-filters-features,
      .campaign-list-filters-countries {
        width: 32%;
      }

      .campaign-list-filters-refine, {
        width: 246px;
      }

      .left-cont {
        margin-right: 1%;

        @apply tw-w-full;
      }

      .right-cont {
        margin-left: 0.5%;
        justify-content: flex-end !important;
      }
    }
  }

  .el-radio-button__inner {
    @apply tw-text-jb-ink tw-text-sm tw-bg-white tw-py-space-2 tw-px-space-3;
  }

  .el-radio-button__orig-radio:checked,
  .el-radio-button__orig-radio:hover {
    + .el-radio-button__inner {
      @apply tw-border-jb-indigo tw-rounded;
    }
  }

  .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    @apply tw-bg-light-bg-color tw-text-jb-ink;
  }

  .app-button {
    @media (min-width: 1300px) {
      width: auto;
    }
  }
}

