
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        




















































































































































.infinite-scroll-list {
  overflow-anchor: none;

  &__transition-group {
    width: 100%;
  }

  &__stopper {
    height: 1px;
    width: 1px;
    pointer-events: none;
    position: relative;
    bottom: 800px;
    z-index: -1;
    background-color: transparent;
  }

  &__loader {
    margin: $--clb-layout-4 0 0 0;
    min-height: 48px;
    grid-column: 1 / -1;

    .el-loading-mask {
      background-color: transparent;
    }
  }

  &__no-content,
  &__end-of-list {
    text-align: center;
    color: $--color-info;
    margin: $--clb-layout-4 0 0;
    font-weight: 300;
    min-height: 48px;
    line-height: 24px;
    grid-column: 1 / -1;
  }
}

@media (min-width: $--xs) {
  .infinite-scroll-list {
    padding: 0;
  }
}
