
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        








































































.campaign-metrics-list {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  height: 54px;
  overflow: hidden;

  .campaign-metric-container {
    max-width: 100%;
    width: auto;
    height: auto;
    text-align: left;

    .campaign-metric {
      margin-bottom: 20px;
      min-width: 60px;
    }
  }
}
