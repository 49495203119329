
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        





















.campaign-card-skeleton {
  width: 100%;
  border: 1px solid $--clb-border-color-base;
  border-radius: $--clb-border-radius;

  .skeleton-box {
    border-radius: $--clb-border-radius;
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 74px;
    padding: $--clb-space-4 $--clb-space-3;
    background: #fff;
    border-radius: $--clb-border-radius;

    .metric {
      margin: $--clb-space-1 0;
    }

    .content-1 {
      margin: 0 auto;
    }
  }
}
