
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        































































































































































































































@import '~@jumbleberry/el-tel-input/dist/elTelInput.css';

.campaign-discover {
  @include page-layout;

  h3 {
    @apply tw-text-headings tw-mb-layout-1;
  }

  &__content {
    &-promoted {
      @apply tw-mb-layout-2 xs:tw-mb-layout-1 sm:tw-mb-0;

      .campaign-story {
        @apply tw-mr-space-3;
      }
    }

    &.campaign-card {
      @apply tw-w-full;
    }
  }

  .infinite-scroll-list__transition-group {
    @apply tw-flex tw-flex-wrap tw-w-full;

    .campaign-card {
      @apply tw-flex-grow-0 tw-flex-shrink;
    }
  }

  &__featured-campaigns {
    @apply tw-mb-layout-3 md:tw-mb-space-6;
  }

  .flickity-page-dots {
    @apply tw--bottom-layout-3;
  }
}

@supports (display: grid) {
  .campaign-discover {
    .infinite-scroll-list__transition-group {
      @include responsive-grid(3);
    }
  }
}
