
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        


























.campaign-metric {
  padding-right: $--clb-space-2;
  display: inline-block;
  min-width: 60px;

  &.disabled {
    color: $--clb-disabled-color;
  }

  &__price {
    font-weight: 700;
    font-size: $--font-size-base;
    color: #262626;
    margin-bottom: 2px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__quantity,
  &__type {
    color: $--clb-body-font;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__quantity {
    font-size: 10px;
    color: $--clb-camp-details-standards-color;
  }
}
