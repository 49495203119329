
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        

































































































































































































































































































































.badge-animate-enter-active {
  transition: all 0.5s ease-out;
}

.badge-animate-leave-active {
  transition: all 0.3s ease-out;
}

.badge-animate-leave-active,
.badge-animate-enter {
  transform: translateY(200px);
  opacity: 0;
}

@media screen and (min-width: $--xs) {
  .badge-animate-leave-active,
  .badge-animate-enter {
    transform: translateX(200px);
    opacity: 0;
  }
}
